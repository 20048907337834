import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import css from './Qa.module.css';
import arrow from '../../../../../assets/images/right-arrow.svg'

const qa = (props) => {
  const {t} = useTranslation('signup');
  const [isOpened, setOpened] = useState(false);

  return (
    <div className={css.Qa} onClick={() => setOpened(!isOpened)}>
      <div className={css.Question}>
        <div>{props.question}</div>
        <div><img src={arrow} alt={t('infoPanel.faq.showMore', 'Show more')} className={isOpened ? css.Rotate : null} /></div>
      </div>
      <div className={css.Answer + ' ' + (isOpened ? null : css.Hidden)}>{props.children}</div>
    </div>
  );
}

export default qa;
