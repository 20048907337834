import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageDetect from 'languagedetect';

import ReviewCard from './ReviewCard/ReviewCard';
import useInterval from '../../../../hooks/useInterval';

import css from './ReviewCaroussel.module.css';

const lngDetector = new LanguageDetect();

const ReviewCaroussel = (props) => {
  const { i18n } = useTranslation('signup');
  const [reviews, setReviews] = useState({ en: [], fr: [] });
  const [currentReview, setCurrentReview] = useState(0);

  const lang = i18n.language;

  useEffect(() => {
    async function getReviews() {
      try {
        const allReviews = await fetch('https://gestion.dev.busterfetcher.com/api/reviews').then(
          (res) => res.json()
        );
        if (allReviews) {
          shuffle(allReviews);
          setReviews({
            fr: allReviews.filter((review) => isFrench(review.content)),
            en: allReviews.filter((review) => !isFrench(review.content)),
          });
        }
      } catch (err) {
        console.log(err);
      }
    }

    getReviews();
  }, []);

  function next() {
    let next = currentReview + 1;
    if (next >= reviews[lang].length) next = 0;
    setCurrentReview(next);
  }

  function previous() {
    let previous = currentReview - 1;
    if (previous < 0) previous = reviews[lang].length - 1;
    setCurrentReview(previous);
  }

  useInterval(() => {
    next();
  }, 10000);

  return (
    <div className={css.ReviewCaroussel}>
      {reviews[lang].length ? (
        <>
          <div className={css.buttons}>
            <div onClick={previous}>
              <div>&#x3C;</div>
            </div>
            <div onClick={next}>
              <div>&#x3E;</div>
            </div>
          </div>
          <ReviewCard review={reviews[lang][currentReview]} />
        </>
      ) : null}
    </div>
  );
};

function isFrench(s) {
  const res = lngDetector.detect(s, 1);
  return res[0][0] === 'french';
}

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export default ReviewCaroussel;
