import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import css from './Header.module.css';
import { connect } from 'react-redux';
import logo from './../../../assets/images/BusterFetcher-Logo.svg';

const Header = ({ currentStep, dev }) => {
  const { t, i18n } = useTranslation('signup');
  let { path } = useParams();
  path = path ? '/' + path : '';

  const changeLanguage = (language) => {
    // Change language
    i18n.changeLanguage(language);

    // Define current step number
    let stepNumber = 0;
    if (currentStep === 'account') stepNumber = 1;
    if (currentStep === 'carriers') stepNumber = 2;
    if (currentStep === 'claimInfo') stepNumber = 3;
    if (currentStep === 'confirmation') stepNumber = 4;

    // Push event to Google Analytics
    window.dataLayer.push({
      event: 'virtual_pageview',
      virtualPageURL: `/virtual/${language}/step${stepNumber}`,
    });
  };

  let language = (
    <Link to={'/fr' + path} onClick={() => changeLanguage('fr')}>
      FR
    </Link>
  );
  let siteUrl = 'https://www.busterfetcher.com/en-ca/';
  let loginUrl = dev
    ? 'https://clients.dev.busterfetcher.com'
    : 'https://clients.busterfetcher.com';
  document.title = 'Buster Fetcher - Sign Up';

  if (i18n.language === 'fr') {
    language = (
      <Link to={'/en' + path} onClick={() => changeLanguage('en')}>
        EN
      </Link>
    );
    siteUrl = 'https://www.busterfetcher.com/';
    loginUrl = dev
      ? 'https://clients.dev.busterfetcher.com/login/fr'
      : 'https://clients.busterfetcher.com/login/fr';
    document.title = 'Buster Fetcher - Inscription';
  }

  return (
    <header className={css.Header}>
      <div>
        <a href={siteUrl}>
          <img src={logo} alt="Buster Fetcher" />
        </a>
      </div>
      <div className={css.Menu}>
        <div className={css.Black}>{t('header.alreadyUser')}</div>
        <div className={css.White}>
          <a href={loginUrl}>{t('header.login')}</a>
        </div>
        <div className={css.Black}>|</div>
        <div className={css.White}>{language}</div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    currentStep: state.currentStep,
    dev: state.dev,
  };
};

export default connect(mapStateToProps)(Header);
