import React, { useState } from 'react';

import css from './Select.module.css';

const Select = ({ options, placeholder, error, half, onChange, ...props }) => {
  const [hasFocus, setFocus] = useState(false);
  const [value, setValue] = useState(props.value);
  return (
    <div
      className={[
        css.Select,
        hasFocus || value ? css.HasFocus : null,
        error ? css.Error : null,
        half ? css.Half : null,
      ].join(' ')}
    >
      <span>{placeholder}</span>
      <select
        {...props}
        half={null}
        error={null}
        placeholder={null}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        onChange={(event) => {
          setValue(event.target.value);
          onChange && onChange(event);
        }}
      >
        <option></option>
        {options.map((option) => {
          if (typeof option === 'string') {
            return (
              <option value={option} key={option}>
                {option}
              </option>
            );
          } else {
            return (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            );
          }
        })}
      </select>
    </div>
  );
};

export default Select;
