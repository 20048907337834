import * as actionTypes from './actions';
import Cookies from 'js-cookie';
import queryString from 'query-string';

const isDev =
  window.location.href.indexOf('dev') > 0 || window.location.href.indexOf('localhost') > 0;

const initialState = {
  dev: isDev,
  referrer: queryString.parse(window.location.search).ref || Cookies.get('referrer'),
  currentStep: 'account',
  token: isDev ? '1cb83fe7ce50afa0dca44947348462a6c90a74d1' : '',
  uniqueKey: 0,
  carriers: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.step,
      };

    case actionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };

    case actionTypes.ADD_CARRIER:
      return {
        ...state,
        carriers: [...state.carriers, { key: state.uniqueKey, step: 'connect', ...action.carrier }],
        uniqueKey: state.uniqueKey + 1,
      };

    case actionTypes.SET_CARRIER_ID:
      const updatedIdCarriers = state.carriers.map((carrier) => {
        if (carrier.key === action.key) carrier.id = action.id;
        return carrier;
      });
      return {
        ...state,
        carriers: updatedIdCarriers,
      };

    case actionTypes.SET_CARRIER_STEP:
      const updatedStepCarriers = state.carriers.map((carrier) => {
        if (carrier.key === action.key) carrier.step = action.step;
        return carrier;
      });
      return {
        ...state,
        carriers: updatedStepCarriers,
      };

    case actionTypes.SET_CARRIER_PROPS:
      const updatedPropsCarriers = state.carriers.map((carrier) => {
        if (carrier.key === action.key) carrier = { ...carrier, ...action.props };
        return carrier;
      });
      return {
        ...state,
        carriers: updatedPropsCarriers,
      };

    case actionTypes.REMOVE_CARRIER:
      const newCarriers = state.carriers.filter((carrier) => carrier.key !== action.key);
      return {
        ...state,
        carriers: newCarriers,
      };

    default:
      return state;
  }
};

export default reducer;
