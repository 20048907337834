import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import publicIp from 'public-ip';

import css from './ClubInfo.module.css';
import axios from '../../../../axios-bf';
import * as actionTypes from '../../../../store/actions';

import Form from '../../../UI/Form/Form';
import Input from '../../../UI/Input/Input';
import Select from '../../../UI/Select/Select';
import Button from '../../../UI/Button/Button';
import ErrorMessage from '../../../UI/ErrorMessage/ErrorMessage';

const ClubInfo = (props) => {
  const { t, i18n } = useTranslation('signup');
  const [info, setInfo] = useState({});
  const [error, setError] = useState('');
  const [clubError, setClubError] = useState('');
  const [termsError, setTermsError] = useState(false);
  const [errorFields, setErrorFields] = useState({});

  useEffect(() => {
    // Push event to Google Analytics
    window.dataLayer.push({
      event: 'virtual_pageview',
      virtualPageURL: `/virtual/${i18n.language}/step3`,
    });
  }, []);

  const onChange = (event) => {
    setInfo({
      ...info,
      [event.target.name]: event.target.value,
    });
    setErrorFields({
      ...errorFields,
      [event.target.name]: false,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    // Validation
    const clubErrors = [];
    let termsError = false;
    const fields = [];

    if (!info.vertical || !info.vertical.trim()) {
      clubErrors.push(t('clubInfo.vertical'));
      fields.push('vertical');
    }
    if (!info.competitor || !info.competitor.trim()) {
      clubErrors.push(t('clubInfo.competitor'));
      fields.push('competitor');
    }
    if (!info.total_opportunity_size || !info.total_opportunity_size.trim()) {
      clubErrors.push(t('clubInfo.total_opportunity_size'));
      fields.push('total_opportunity_size');
    }
    if (!info.purolator_opportunity_size || !info.purolator_opportunity_size.trim()) {
      clubErrors.push(t('clubInfo.purolator_opportunity_size'));
      fields.push('purolator_opportunity_size');
    }
    if (!info.long_heavy || !info.long_heavy.trim()) {
      clubErrors.push(t('clubInfo.long_heavy'));
      fields.push('long_heavy');
    }
    if (!info.product_description || !info.product_description.trim()) {
      clubErrors.push(t('clubInfo.product_description'));
      fields.push('product_description');
    }
    if (!info.description || !info.description.trim()) {
      clubErrors.push(t('clubInfo.description'));
      fields.push('description');
    }
    if (!info.terms_conditions) {
      termsError = true;
      fields.push('terms_conditions');
    }
    if (!info.puro_terms_conditions) {
      termsError = true;
      fields.push('puro_terms_conditions');
    }

    if (clubErrors.length) {
      let messageStart = t('common.validField', 'Please enter a valid') + ' ';
      if (clubErrors.length > 1)
        messageStart = t('common.validFields', 'Please enter valid:') + ' ';
      // setClubError(messageStart + clubErrors.join(', ') + '.');
      setClubError(
        <>
          {messageStart}
          {clubErrors.map((e) => (
            <li>{e}</li>
          ))}
        </>
      );
    } else {
      setClubError('');
    }

    if (termsError) {
      setTermsError(true);
    } else {
      setTermsError(false);
    }

    if (clubErrors.length || termsError) {
      setErrorFields(Object.assign({ errorFields }, ...fields.map((field) => ({ [field]: true }))));
      return;
    }

    // Send request to API
    const params = {
      token: props.token,
      vertical: info.vertical,
      competitor: info.competitor,
      totalOpportunitySize: info.total_opportunity_size,
      purolatorOpportunitySize: info.purolator_opportunity_size,
      accountNumber: info.account_number,
      longHeavy: info.long_heavy,
      longHeavyPercentage: info.long_heavy_percentage,
      productDescription: info.product_description,
      additionalDescription: info.description,
      termsConditions: new Date(),
      termsConditionsIP: await publicIp.v4(),
    };
    axios
      .post('/subscribe/shipping-club', params)
      .then((res) => {
        console.log(res);
        // Handle error message
        if (res.data && res.data.error) {
          setError(res.data.error);
        }
        // Handle success
        else if (res.data && res.data.id) {
          props.setCurrentStep('companyInfo');
        }
        // Unexpected error
        else {
          setError(t('common.unexpectedError', 'We are sorry but an unexpected error occured.'));
        }
      })
      .catch((error) => {
        // Connexion error
        setError(error);
      });
  };

  // Handle error messages
  let errorMessage = null;
  if (error) {
    errorMessage = <ErrorMessage>{error}</ErrorMessage>;
  }

  let clubErrorMessage = null;
  if (clubError) {
    clubErrorMessage = <ErrorMessage>{clubError}</ErrorMessage>;
  }

  let termsErrorMessage = null;
  if (termsError) {
    termsErrorMessage = <ErrorMessage>{t('clubInfo.termsError')}</ErrorMessage>;
  }

  return (
    <div className={css.ClubInfo}>
      <h1>{t('clubInfo.title', 'Shipping Club Information')}</h1>
      <p>
        {t(
          'clubInfo.complete',
          'Please complete the form below to join the Buster Fetcher Shipping Club.'
        )}
      </p>

      <form onSubmit={onSubmit}>
        <div className={css.Forms}>
          {errorMessage}

          <Form>
            {clubErrorMessage}
            <Select
              name="vertical"
              placeholder={t('clubInfo.vertical')}
              onChange={onChange}
              error={errorFields.vertical}
              options={[
                { label: t('clubInfo.automotive'), value: 'Automotive' },
                { label: t('clubInfo.consumer'), value: 'Consumer' },
                { label: t('clubInfo.energy'), value: 'Energy' },
                { label: t('clubInfo.financial'), value: 'Financial' },
                { label: t('clubInfo.food_beverage'), value: 'Food & beverage' },
                { label: t('clubInfo.public_sector'), value: 'Government/Public Sector' },
                { label: t('clubInfo.healthcare'), value: 'Healthcare' },
                { label: t('clubInfo.retail'), value: 'Retail' },
                { label: t('clubInfo.technology'), value: 'Technology' },
                { label: t('clubInfo.telecommunication'), value: 'Telecommunication' },
                { label: t('clubInfo.transportation'), value: 'Transportation' },
                { label: t('clubInfo.wholesale_distribution'), value: 'Wholesale & Distribution' },
                { label: t('clubInfo.other'), value: 'Other' },
              ]}
            />
            <Select
              name="competitor"
              placeholder={t('clubInfo.competitor')}
              onChange={onChange}
              error={errorFields.competitor}
              options={[
                { label: '3PL', value: '3PL' },
                { label: 'Ace Courier', value: 'Ace Courier' },
                { label: 'ATS', value: 'ATS' },
                { label: t('clubInfo.canada_post'), value: 'Canada Post' },
                { label: 'Canpar', value: 'Canpar' },
                { label: 'DHL', value: 'DHL' },
                { label: 'Dicom', value: 'Dicom' },
                { label: 'Dynamex', value: 'Dynamex' },
                { label: 'FedEx Express', value: 'FedEx Express' },
                { label: 'FedEx Ground', value: 'FedEx Ground' },
                { label: 'ICS', value: 'ICS' },
                { label: t('clubInfo.independent'), value: 'Independent' },
                { label: 'Midland', value: 'Midland' },
                { label: 'NationEx', value: 'NationEx' },
                { label: t('clubInfo.priority_courier'), value: 'Priority Courier' },
                { label: t('clubInfo.sameday'), value: 'Sameday' },
                { label: 'Tiger Courier', value: 'Tiger Courier' },
                { label: 'TNT', value: 'TNT' },
                { label: 'UPS', value: 'UPS' },
                { label: t('clubInfo.other'), value: 'Other' },
              ]}
            />
            <Input
              type="number"
              name="total_opportunity_size"
              placeholder={t('clubInfo.total_opportunity_size')}
              onChange={onChange}
              error={errorFields.total_opportunity_size}
            />
            <Input
              type="number"
              name="purolator_opportunity_size"
              placeholder={t('clubInfo.purolator_opportunity_size')}
              onChange={onChange}
              error={errorFields.purolator_opportunity_size}
            />
            <Input
              name="account_number"
              placeholder={t('clubInfo.account_number')}
              onChange={onChange}
              error={errorFields.account_number}
            />
            <Select
              name="long_heavy"
              placeholder={t('clubInfo.long_heavy')}
              onChange={onChange}
              error={errorFields.long_heavy}
              options={[
                { label: t('clubInfo.yes'), value: 'Yes' },
                { label: t('clubInfo.no'), value: 'No' },
              ]}
            />
            <Input
              name="long_heavy_percentage"
              placeholder={t('clubInfo.long_heavy_percentage')}
              onChange={onChange}
              error={errorFields.long_heavy_percentage}
            />
            <Input
              name="product_description"
              placeholder={t('clubInfo.product_description')}
              onChange={onChange}
              error={errorFields.product_description}
            />
            <Input
              name="description"
              placeholder={t('clubInfo.description')}
              onChange={onChange}
              error={errorFields.description}
            />

            {termsErrorMessage}
            <Input
              type="checkbox"
              name="terms_conditions"
              placeholder={t('clubInfo.terms_conditions')}
              onChange={onChange}
              error={errorFields.terms_conditions}
            />
            <Input
              type="checkbox"
              name="puro_terms_conditions"
              placeholder={t('clubInfo.puro_terms_conditions')}
              onChange={onChange}
              error={errorFields.puro_terms_conditions}
            />
          </Form>

          <div className={css.Next}>
            <Button>{t('clubInfo.submit', 'Confirm claim information')}</Button>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentStep: (step) => dispatch({ type: actionTypes.SET_CURRENT_STEP, step: step }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClubInfo);
