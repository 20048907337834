import React from 'react';

import css from './Form.module.css';

const form = (props) => {
  const title = props.title ? <h2>{props.title}</h2> : null;
  const description = props.description ? <p>{props.description}</p> : null;
  return (
    <div className={css.Form}>
      {title}
      {description}
      {props.children}
    </div>
  );
};

export default form;
