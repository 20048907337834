import React from 'react';
import { useTranslation } from 'react-i18next';

import css from './Signup.module.css';
import { connect } from 'react-redux';
import axios from '../../axios-bf';
import axiosErrorHandler from '../../hoc/axiosErrorHandler';

import Header from '../../components/Signup/Header/Header';
import InfoPanel from '../../components/Signup/InfoPanel/InfoPanel';
import ProgressBar from '../../components/Signup/ProgressBar/ProgressBar';
import Page from '../../components/Signup/Page/Page';
import Account from '../../components/Signup/Page/Account/Account';
import Carriers from '../../components/Signup/Page/Carriers/Carriers';
import ClubInfo from '../../components/Signup/Page/ClubInfo/ClubInfo';
import ClaimInfo from '../../components/Signup/Page/ClaimInfo/ClaimInfo';
import CompanyInfo from '../../components/Signup/Page/CompanyInfo/CompanyInfo';
import Confirmation from '../../components/Signup/Page/Confirmation/Confirmation';
import HubspotForm from '../../components/Signup/Page/HubspotForm/HubspotForm';

const Signup = (props) => {
  const { i18n } = useTranslation('signup');

  // Change language if needed
  if (props.match.params.locale !== i18n.language) {
    i18n.changeLanguage(props.match.params.locale);
  }

  let page = <Account />;
  if (props.currentStep === 'carriers') page = <Carriers />;
  if (props.currentStep === 'clubInfo') page = <ClubInfo />;
  if (props.currentStep === 'claimInfo') page = <ClaimInfo />;
  if (props.currentStep === 'companyInfo') page = <CompanyInfo />;
  if (props.currentStep === 'confirmation') page = <Confirmation />;
  if (props.currentStep === 'hubspotForm') page = <HubspotForm />;

  return (
    <div className={css.Signup}>
      <Header />
      <ProgressBar />
      <Page>{page}</Page>
      <InfoPanel />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentStep: state.currentStep,
  };
};

export default connect(mapStateToProps)(axiosErrorHandler(Signup, axios));
