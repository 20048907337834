import axios from 'axios';

let dev = window.location.href.indexOf('dev') > 0 || window.location.href.indexOf('localhost') > 0;
let baseURL = dev
  ? 'https://gestion.dev.busterfetcher.com/api/'
  : 'https://gestion.busterfetcher.com/api/';

const instance = axios.create({
  baseURL: baseURL,
});

export default instance;
