import React from 'react';

import css from './ReviewCard.module.css';
import star from '../../../../../assets/images/star.png';
import halfStar from '../../../../../assets/images/half-star.png';

const Stars = ({ rating }) => {
  let stars = [];
  for (let i = 0; i < Math.floor(rating); i++) stars.push(<img src={star} alt="*" key={i} />);
  if (rating - Math.floor(rating) === 0.5) stars.push(<img src={halfStar} alt="." key="last" />);

  return <>{stars.map((s) => s)}</>;
};

const ReviewCard = (props) => {
  if (!props.review) return null;

  const { title, content, rating, firstName, lastName, created } = props.review;
  const overall = rating.find((r) => r.type === 'overall').rating;

  return (
    <div className={css.ReviewCard}>
      <div>
        <Stars rating={overall} />
      </div>
      <p>
        <b>"{title}"</b>
      </p>
      <p>{content}</p>
      <p>
        {firstName} {lastName} <span className={css.date}>- {created.substr(0, 10)}</span>
      </p>
    </div>
  );
};

export default ReviewCard;
