import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import css from './ProgressBar.module.css';
import * as actionTypes from '../../../store/actions';

const ProgressBar = ({ currentStep, dev, setCurrentStep, setToken }) => {
  const { t } = useTranslation('signup');
  const { path } = useParams();

  const setStep = (step) => {
    dev && setCurrentStep(step);
  };

  if (currentStep === 'hubspotForm') return <div className={css.ProgressBar}></div>;

  return (
    <div className={css.ProgressBar}>
      <div
        className={currentStep === 'account' ? css.Active : null}
        onClick={() => setStep('account')}
      >
        {t('progressBar.account')}
      </div>

      {path === 'club' ? (
        <>
          <div
            className={currentStep === 'clubInfo' ? css.Active : null}
            onClick={() => setStep('clubInfo')}
          >
            {t('progressBar.clubInfo')}
          </div>
          <div
            className={currentStep === 'companyInfo' ? css.Active : null}
            onClick={() => setStep('companyInfo')}
          >
            {t('progressBar.companyInfo')}
          </div>
        </>
      ) : (
        <>
          <div
            className={currentStep === 'carriers' ? css.Active : null}
            onClick={() => setStep('carriers')}
          >
            {t('progressBar.carriers')}
          </div>
          <div
            className={currentStep === 'claimInfo' ? css.Active : null}
            onClick={() => setStep('claimInfo')}
          >
            {t('progressBar.claimInfo')}
          </div>
        </>
      )}

      <div
        className={currentStep === 'confirmation' ? css.Active : null}
        onClick={() => setStep('confirmation')}
      >
        {t('progressBar.confirmation')}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentStep: state.currentStep,
    dev: state.dev,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentStep: (step) => dispatch({ type: actionTypes.SET_CURRENT_STEP, step: step }),
    setToken: (token) => dispatch({ type: actionTypes.SET_TOKEN, token: token }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBar);
