import React from 'react';

import css from './Button.module.css';

const button = (props) => {
  return (
    <button className={css.Button} {...props}>
      {props.children}
    </button>
  );
};

export default button;
