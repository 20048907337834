import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import useInterval from '../../../../hooks/useInterval';
import { useTranslation } from 'react-i18next';
import useResizeAware from 'react-resize-aware';

import css from './Carriers.module.css';
import * as actionTypes from '../../../../store/actions';

import CarrierButton from './CarrierButton/CarrierButton';
import CarrierBox from './CarrierBox/CarrierBox';
import Button from '../../../UI/Button/Button';
import ErrorMessage from '../../../UI/ErrorMessage/ErrorMessage';

import canadapostLogo from '../../../../assets/images/logo-canada-post.svg';
import purolatorLogo from '../../../../assets/images/logo-purolator.svg';
import fedexLogo from '../../../../assets/images/logo-fedex.svg';
import upsLogo from '../../../../assets/images/logo-ups.svg';

const carriers = (props) => {
  const { t, i18n } = useTranslation('signup');
  const [resizeListener, sizes] = useResizeAware();
  const [carriersConfig] = useState([
    {
      name: 'canadapost',
      title: t('carriers.canadapost.title', 'Canada Post'),
      logo: canadapostLogo,
    },
    {
      name: 'purolator',
      title: t('carriers.purolator.title', 'Purolator'),
      logo: purolatorLogo,
    },
    {
      name: 'fedex',
      title: t('carriers.fedex.title', 'FedEx'),
      logo: fedexLogo,
    },
    {
      name: 'ups',
      title: t('carriers.ups.title', 'UPS'),
      logo: upsLogo,
    },
  ]);
  const [error, setError] = useState('');
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    // Push event to Google Analytics
    window.dataLayer.push({
      event: 'virtual_pageview',
      virtualPageURL: `/virtual/${i18n.language}/step2`,
    });
  }, []);

  useInterval(() => {
    const connectedCarriers =
      props.carriers.filter((carrier) => carrier.step === 'done').length > 0;
    if (connectedCarriers) setConnected(true);
  }, 200);

  // Carrier boxes
  let carrierBoxes = null;
  if (props.carriers.length) {
    carrierBoxes = props.carriers.map((carrier) => {
      return (
        <CarrierBox
          key={carrier.key}
          carrier={carrier}
          remove={() => props.removeCarrier(carrier.key)}
        />
      );
    });
  }

  // Carrier buttons
  const carrierBoxOpened = props.carriers.filter((carrier) => carrier.step !== 'done').length;
  const carrierButtons =
    !carrierBoxOpened &&
    carriersConfig.map((carrier) => {
      return (
        <CarrierButton
          key={carrier.name}
          imgSrc={carrier.logo}
          addCarrier={() => props.addCarrier(carrier)}
        />
      );
    });

  const submit = () => {
    // Validation
    let error = null;

    // Check if at least one carrier is connected
    if (props.carriers.length > 0) {
      const connectedCarriers = props.carriers.filter((carrier) => carrier.step === 'done');
      if (!connectedCarriers.length) {
        error = t('carriers.atLeastOne', 'You must connect at least one carrier.');
      }
    } else {
      error = t('carriers.atLeastOne', 'You must connect at least one carrier.');
    }

    if (error) {
      setError(error);
      return;
    }

    setError('');

    props.setCurrentStep('claimInfo');
  };

  // Error message
  let errorMessage = null;
  if (error) {
    errorMessage = <ErrorMessage>{error}</ErrorMessage>;
  }

  return (
    <Fragment>
      {resizeListener}
      <div className={css.Carriers}>
        <h1>{t('carriers.title', 'Connect your carriers')}</h1>

        {errorMessage}

        {carrierBoxes}

        {carrierButtons ? (
          <>
            <h2>{t('carriers.addCarriers', 'Add Carriers')}</h2>
            <div className={css.CarrierButtons}>{carrierButtons}</div>
          </>
        ) : null}

        {/* {carrierBoxes ? <h2>{t('carriers.selectedCarriers', 'My Selected Carriers')}</h2> : null} */}
      </div>

      {connected ? (
        <div className={css.StickyAction} style={{ width: sizes.width }}>
          <Button onClick={submit}>{t('carriers.submit', 'Continue')}</Button>
        </div>
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
    carriers: state.carriers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCarrier: (carrier) => dispatch({ type: actionTypes.ADD_CARRIER, carrier: carrier }),
    setCarrierId: (key, id) => dispatch({ type: actionTypes.SET_CARRIER_ID, key: key, id: id }),
    setCarrierProps: (key, props) =>
      dispatch({ type: actionTypes.SET_CARRIER_PROPS, key: key, props: props }),
    removeCarrier: (key) => dispatch({ type: actionTypes.REMOVE_CARRIER, key: key }),
    setCurrentStep: (step) => dispatch({ type: actionTypes.SET_CURRENT_STEP, step: step }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(carriers);
