import React from 'react';
import { useTranslation } from 'react-i18next';

import css from './LatesInfo.module.css';
import ReviewCaroussel from '../ReviewCaroussel/ReviewCaroussel';
import Faq from '../Faq/Faq';
import Qa from '../Faq/Qa/Qa';

const LatesInfo = () => {
  const { t } = useTranslation('signup');

  return (
    <div className={css.LatesInfo}>
      <ReviewCaroussel />

      <Faq>
        <Qa question={t('infoPanel.faq.q1')}>{t('infoPanel.faq.a1')}</Qa>
        <Qa question={t('infoPanel.faq.q2')}>{t('infoPanel.faq.a2')}</Qa>
        <Qa question={t('infoPanel.faq.q3')}>{t('infoPanel.faq.a3')}</Qa>
      </Faq>
    </div>
  );
};

export default LatesInfo;
