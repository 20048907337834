import React from 'react';
import { useTranslation } from 'react-i18next';

import css from './CarrierButton.module.css';
import plus from '../../../../../assets/images/plus.svg';
import plusInactive from '../../../../../assets/images/plus-inactive.svg';

const carrierButton = (props) => {
  const { t } = useTranslation('signup');
  return (
    <button
      className={css.CarrierButton}
      id={props.id}
      name={props.name}
      onClick={props.addCarrier}
    >
      <img className={css.Logo} src={props.imgSrc} alt={props.name} />
      <img className={css.AddButtonInactive} src={plusInactive} alt={t('common.add')} />
      <img className={css.AddButton} src={plus} alt={t('common.add')} />
    </button>
  );
};

export default carrierButton;
