import React from 'react';
import { useTranslation } from 'react-i18next';

import css from './ClubInfo.module.css';
import Faq from '../Faq/Faq';
import Qa from '../Faq/Qa/Qa';
import shippingClubLogo from '../../../../assets/images/shipping-club.svg';

const ClubInfo = () => {
  const { t } = useTranslation('signup');

  return (
    <div className={css.ClubInfo}>
      <p className={css.Logo}>
        <img src={shippingClubLogo} alt={t('infoPanel.shippingClub.title')} />
        <br />
        {t('infoPanel.shippingClub.title')}
      </p>

      <p className={css.Info}>{t('infoPanel.shippingClub.desc')}</p>

      <Faq>
        <Qa question={t('infoPanel.shippingClub.faq.q1')}>{t('infoPanel.shippingClub.faq.a1')}</Qa>
        <Qa question={t('infoPanel.shippingClub.faq.q2')}>{t('infoPanel.shippingClub.faq.a2')}</Qa>
        <Qa question={t('infoPanel.shippingClub.faq.q3')}>{t('infoPanel.shippingClub.faq.a3')}</Qa>
        <Qa question={t('infoPanel.shippingClub.faq.q4')}>{t('infoPanel.shippingClub.faq.a4')}</Qa>
      </Faq>
    </div>
  );
};

export default ClubInfo;
