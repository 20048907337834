import React from 'react';
import { useParams } from 'react-router-dom';

import LatesInfo from './LatesInfo/LatesInfo';
import ClubInfo from './ClubInfo/ClubInfo';

const InfoPanel = () => {
  const { path } = useParams();

  switch (path) {
    case 'club':
      return <ClubInfo />;

    default:
      return <LatesInfo />;
  }
};

export default InfoPanel;
